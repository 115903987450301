<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'SyllabusManager',
  page: {
    title: 'Syllabus Manager',
    meta: [
      {
        name: 'description',
        content: 'Manage  Syllabus of each class ',
      },
    ],
  },
}
</script>
